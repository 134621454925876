import {Injectable} from '@angular/core';

import Ably from 'ably/callbacks';
import {Types} from 'ably/promises';
import {NgEventBus} from 'ng-event-bus';

import {env} from '../../../../env/env';

import {BusEvents} from '../../../models/enums/bus-events';
import {AppLogger} from '../../../utils/app-logger';
import {AppUtils} from '../../../utils/app-utils';
import {ApiRealtimeEvents} from '../../../utils/api-realtime-events';

import LogInfo = Types.LogInfo;
import RealtimeCallbacks = Types.RealtimeCallbacks;
import RealtimeChannelCallbacks = Types.RealtimeChannelCallbacks;
import ConnectionStateChange = Types.ConnectionStateChange;
import ErrorInfo = Types.ErrorInfo;
import ChannelStateChange = Types.ChannelStateChange;
import ConnectionState = Types.ConnectionState;

@Injectable()
export class SyncService {

  private client!: RealtimeCallbacks;
  private notificationsChannel!: RealtimeChannelCallbacks;
  private fromError: boolean;

  constructor(private eventBus: NgEventBus) {
    this.fromError = false;
  }

  public start(): void {
    const logger: LogInfo = {
      level: 2,
      handler: (msg: string) => (): void => {
        AppLogger.info(`[Ably] ${String(msg).replace('Ably: ', '')}`);
      }
    };

    const options: Ably.Types.ClientOptions = {
      key: env.ably.key,
      tls: true,
      clientId: AppUtils.generateUUID(),
      transportParams: {
        heartbeatInterval: env.ably.heartbeatInterval,
        remainPresentFor: 1000
      },
      log: logger,
      transports: ['web_socket'],
      queueMessages: false,
      disconnectedRetryTimeout: env.ably.disconnectedTimeout,
      suspendedRetryTimeout: env.ably.suspendedTimeout
    };

    this.client = new Ably.Realtime(options);
    this.notificationsChannel = this.client.channels.get(env.ably.channel);

    this.bindListeners();
    this.bindEvents();
  }

  private bindListeners(): void {
    this.client?.connection.on((stateChange: ConnectionStateChange) => {
      AppLogger.info(`[Ably] Changed connection status from "${stateChange.previous}" to "${stateChange.current}"`);

      const previousState: ConnectionState = stateChange.previous;
      const currentState: ConnectionState = stateChange.current;

      if (previousState === 'connecting' && currentState === 'connected' && this.fromError) {
        AppLogger.info(`[Ably] Reconnected, sending ${BusEvents.RT_API} event...`);
        this.eventBus.cast(BusEvents.RT_API);
        this.fromError = false;
      }

      if (previousState === 'connected' && currentState === 'disconnected') {
        AppLogger.error(`[Ably] Connection lost: ${this.parseAblyError(stateChange)}`);
        this.fromError = true;
      }

      if (previousState === 'disconnected' && currentState === 'connecting') {
        AppLogger.warn('[Ably] Trying to reconnect websocket...');
        this.fromError = true;
      }

      if (previousState === 'connecting' && currentState === 'disconnected') {
        AppLogger.error(`[Ably] Cannot establish connection via websocket: ${this.parseAblyError(stateChange)}`);
        this.fromError = true;
      }

      if (previousState === 'disconnected' && currentState === 'suspended') {
        AppLogger.warn(`[Ably] Trying to reconnect websocket for 2 minutes with error: ${this.parseAblyError(stateChange)}`);
        this.fromError = true;
      }

      if (previousState === 'suspended' && currentState === 'connecting') {
        AppLogger.warn('[Ably] Trying to reconnect websocket again from suspended state...');
        this.fromError = true;
      }

    });

    this.notificationsChannel?.on((stateChange: ChannelStateChange) => {
      AppLogger.info(`[Ably] Changed channel status from "${stateChange.previous}" to "${stateChange.current}"`);

      if (stateChange.current === 'attached') {
        AppLogger.info('[Ably] Connection OK');
      }

    });
  }

  private bindEvents(): void {
    this.notificationsChannel?.subscribe(ApiRealtimeEvents.API, () => this.eventBus.cast(BusEvents.RT_API));
    this.notificationsChannel?.subscribe(ApiRealtimeEvents.APP, () => this.eventBus.cast(BusEvents.RT_APP));
    this.notificationsChannel?.subscribe(ApiRealtimeEvents.PUSH_DEVICES, () => this.eventBus.cast(BusEvents.RT_PUSH_DEVICES));
  }

  private parseAblyError(stateChange: ConnectionStateChange): string {

    if (stateChange === undefined || stateChange === null) {
      return 'Ably didn\'t provide additional info about the connection state change';
    }

    let reason: ErrorInfo | undefined = stateChange.reason;
    let retryIn: number | undefined = stateChange.retryIn;

    if (reason === undefined) {
      reason = {
        name: 'Ably unknown error',
        code: -1,
        message: 'Ably didn\'t provide details of the error',
        statusCode: -1
      };
    }

    if (retryIn === undefined) {
      retryIn = -1;
    }

    let message: string = reason.message;

    if (message === undefined || message === null || message.trim().length === 0) {
      message = 'Ably didn\'t provide an error message';
    }

    let code: number = reason.code;

    if (code === undefined || code === null) {
      code = -1;
    }

    let statusCode: number = reason.statusCode;

    if (statusCode === undefined || statusCode === null) {
      statusCode = -1;
    }

    return `${message} (Code: ${code}, Status: ${statusCode}, Retry in: ${retryIn})`;
  }

}
