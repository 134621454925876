import {Injectable} from '@angular/core';

import {Observable, Subscriber} from 'rxjs';

import {ToastOption} from '../../../models/enums/toast-option';

@Injectable()
export class ToastService {

  public observe(message: string, timeout: number, action1?: string, action2?: string): Observable<ToastOption> {
    return new Observable<ToastOption>((s: Subscriber<ToastOption>) => {
      // TODO: Implement toast service
      s.next(ToastOption.NONE);
      s.complete()
    });
  }

  public show(message: string, timeout: number, action1?: string, action2?: string): void {
    // TODO: Implement toast service
  }
}
