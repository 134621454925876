import {Injectable} from '@angular/core';
import {SwUpdate, UnrecoverableStateEvent, VersionEvent, VersionReadyEvent} from '@angular/service-worker';

import {ToastService} from './toast.service';
import {AlertService} from './alert.service';
import {AlertDTO} from '../../../models/dto/alert-dto';
import {AlertType} from '../../../models/enums/alert-type';
import {ToastOption} from '../../../models/enums/toast-option';
import {AppLogger} from '../../../utils/app-logger';

declare const window: any;

@Injectable()
export class LogUpdateService {

  private showed: boolean;

  constructor(private updates: SwUpdate,
              private toastService: ToastService,
              private alertService: AlertService) {
    this.showed = false;
  }

  public start(): void {
    AppLogger.info('[LogUpdate] Initializing subscription to SW version updates...');

    this.updates.versionUpdates.subscribe((e: VersionEvent) => {
      AppLogger.info(`[LogUpdate] Version update event triggered (Type: ${e.type})`);

      if (e.type === 'VERSION_DETECTED') {
        AppLogger.info(`[LogUpdate] New version detected, starting download of files (Hash: ${e.version.hash})`);
      }

      if (e.type === 'VERSION_READY') {
        const evt: VersionReadyEvent = e;
        AppLogger.info(`[LogUpdate] New version downloaded, ready to install (Current: ${evt.currentVersion.hash}, Latest: ${evt.latestVersion.hash})`);

        if (!this.showed) {
          AppLogger.info('[LogUpdate] Show update toast to user');
          this.toastService.observe('Actualización disponible', 86400000, 'RECARGAR')
            .subscribe((r: ToastOption) => {
              if (r === ToastOption.OPTION_1) this.reloadApp();
            });
          this.showed = true;
        } else {
          AppLogger.info('[LogUpdate] Update toast already showed');
        }
      }

      if (e.type === 'VERSION_INSTALLATION_FAILED') {
        AppLogger.error(`[LogUpdate] New version installation failed (Latest: ${e.version.hash})`, e.error);
        this.errorReloadApp();
      }

    });

    this.updates.unrecoverable.subscribe((e: UnrecoverableStateEvent) => {
      AppLogger.error(`[LogUpdate] There was an unrecoverable state event: ${e.reason}, force app reloading`);
      this.errorReloadApp();
    });

  }

  private errorReloadApp(): void {
    const data: AlertDTO = {
      type: AlertType.ERROR,
      title: 'Error interno',
      body: 'Se ha detectado un error de versión en la aplicación, se recargará en 5 segundos',
      okText: 'Recargar ya',
      okCallback: () => window.location.reload(true)
    };

    this.alertService.showAlert(data);
    setTimeout(() => window.location.reload(true), 5000)
  }

  private reloadApp(): void {
    AppLogger.info('[LogUpdate] Reloading app to install new update...');

    setTimeout(() => {
      AppLogger.info('[LogUpdate] Installing new app version...');
      this.updates.activateUpdate()
        .then(() => window.location.reload(true))
        .catch(reason => AppLogger.error('[LogUpdate] Error installing new app version', reason));
    }, 10);
  }
}
