import {Injectable, NgZone} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {Router} from '@angular/router';

import {Subscription} from 'rxjs';
import {NgEventBus} from 'ng-event-bus';

import {env} from '../../../../env/env';

import {ToastService} from './toast.service';
import {StoreService} from './store.service';
import {BusEvents} from '../../../models/enums/bus-events';
import {Version} from '../../../models/version';
import {AppLogger} from '../../../utils/app-logger';

@Injectable()
export class CheckForUpdateService {

  private updateWebSub!: Subscription;

  constructor(private updates: SwUpdate,
              private ngZone: NgZone,
              private router: Router,
              private toastService: ToastService,
              private storeService: StoreService,
              private eventBus: NgEventBus) {
  }

  public start(): void {
    AppLogger.info('[CheckUpdate] Starting app update check...');

    this.updateWebSub = this.eventBus.on(BusEvents.RT_APP)
      .subscribe(() => {
        AppLogger.info('[CheckUpdate] Starting app update check from Ably...');
        this.checkForUpdate();
      });

    this.checkForUpdate();
  }

  private checkForUpdate(): void {

    if (!this.updates.isEnabled) {
      AppLogger.warn('[CheckUpdate] Service worker disabled');
      return;
    }

    AppLogger.info('[CheckUpdate] Checking...');

    this.updates.checkForUpdate()
      .then((newVersion: boolean) => {
        if (newVersion) {
          AppLogger.info('[CheckUpdate] New app update found!');
        } else {
          AppLogger.info('[CheckUpdate] NO new app update found!');
          this.showUpdatedAppNotification();
        }
      })
      .catch(reason => AppLogger.error('[CheckUpdate] Error checking app update', reason));

  }

  private showUpdatedAppNotification(): void {
    AppLogger.info('[CheckUpdate] Checking if version installed is different from previous one...');

    setTimeout(() => {
      const version: Version | undefined = this.storeService.getVersion();
      const isNewVersion: boolean = version?.v !== env.version || false;

      if (isNewVersion) {
        AppLogger.info('[CheckUpdate] New version installation found, show toast to user');
        this.toastService.show('Aplicación actualizada', 3000);
      } else {
        AppLogger.info('[CheckUpdate] No new version installation detected');
      }

      this.storeService.setVersion({v: env.version})
      AppLogger.info(`[CheckUpdate] Set current version mark to: ${env.version}`);
    }, 1000);

  }

}
